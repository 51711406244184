<template>
  <section>
    <div
      class="u-flex u-flex-col sm:u-flex-row sm:u-gap-x-5 xl:u-gap-x-12 u-max-w-[527px] sm:u-max-w-[1149px] u-mx-auto u-px-[29px] u-pt-[20px] u-pb-[60px] sm:u-pt-[48px] sm:u-pb-[88px] xl:u-px-0">
      <picture
        class="u-mx-auto u-inline-block u-w-[255px] u-h-auto min-h-[222px] sm:u-w-[300px] sm:u-min-h-[261px] xl:u-w-[600px] xl:u-min-h-[521px]">
        <source
          media="(min-width: 1280px)"
          srcset="
            /img/greeting-xl@1x.webp 1x,
            /img/greeting-xl@2x.webp 2x,
            /img/greeting-xl@3x.webp 3x
          " />
        <source
          media="(min-width: 640px)"
          srcset="
            /img/greeting-sm@1x.webp 1x,
            /img/greeting-sm@2x.webp 2x,
            /img/greeting-sm@3x.webp 3x
          " />
        <source
          media="(min-width: 0px)"
          srcset="
            /img/greeting-xs@1x.webp 1x,
            /img/greeting-xs@2x.webp 2x,
            /img/greeting-xs@3x.webp 3x
          " />
        <img
          src="/img/greeting.webp"
          alt="greeting"
          class="u-object-contain u-w-full u-h-full" />
      </picture>
      <div class="sm:u-flex-1 sm:u-pt-[42px]">
        <h1
          class="u-mt-0 u-mb-10 u-underline sm:u-no-underline u-text-[#1C1C1C] u-font-bold u-text-[26px] u-leading-[40px] sm:u-text-[36px] sm:u-leading-[50px]">
          {{ $t('login.title') }}
          <span class="u-hidden sm:u-inline-block">GoDuck VPN</span>
        </h1>
        <q-form @submit="onSubmit">
          <q-input
            name="username"
            id="username"
            :rules="[
              (val) => !!val || $t('form.errors.required'),
              (val) => validateUsername(val) || $t('form.errors.username')
            ]"
            v-model="formParams.username"
            lazy-rules
            :placeholder="$t('login.usenamePlaceholder')"
            rounded
            outlined
            autofocus>
            <template v-slot:prepend>
              <q-btn flat round disable>
                <q-img src="icons/admin-line.svg" class="u-w-4 u-h-auto" />
              </q-btn>
            </template>
          </q-input>
          <q-input
            name="password"
            id="password"
            :rules="[
              (val) => !!val || $t('form.errors.required'),
              (val) =>
                validatePasswordLetter(val) || $t('form.errors.passwordLetter'),
              (val) =>
                validatePasswordLength(val) || $t('form.errors.passwordLength')
            ]"
            v-model="formParams.password"
            lazy-rules
            :type="!isPassword ? 'password' : 'text'"
            :placeholder="$t('login.passwordPlaceholder')"
            rounded
            outlined>
            <template v-slot:prepend>
              <q-btn flat round disable icon="svguse:icons/bx.svg#lock-open" />
            </template>
            <template v-slot:append>
              <q-btn
                flat
                round
                icon="img:icons/eye-notshow-line.svg"
                :class="{ 'u-hidden': !eyeIcon }"
                @click="changeVisibility" />
              <q-btn
                flat
                round
                icon="img:icons/eye-show-line.svg"
                :class="{ 'u-hidden': eyeIcon }"
                @click="changeVisibility" />
            </template>
          </q-input>
          <q-btn
            :label="$t('login.submitBtn')"
            :loading="isLoading"
            type="submit"
            rounded
            flat
            class="u-mb-4 sm:u-mt-[18px] u-w-full u-min-h-10 u-bg-[#FCB400] u-font-bold u-text-[16px] u-leading-[36px] sm:u-h-[58px] sm:u-text-[20px]" />
          <div
            class="u-flex u-flex-col u-gap-y-4 sm:u-flex-row sm:u-justify-between sm:u-items-center">
            <q-btn
              :label="$t('login.signupBtn')"
              :loading="isLoading"
              :to="'/signup?lang=' + userStore.lang"
              type="button"
              rounded
              flat
              class="sm:u-order-1 u-w-full sm:u-w-[120px] u-min-h-10 u-border u-border-solid u-border-[#D5D5D5] u-text-sm" />
            <div>
              <router-link
                class="u-text-[#676767]"
                :to="'/forget-password?lang=' + userStore.lang">
                {{ $t('login.forgetPasswordBtn') }}
              </router-link>
            </div>
          </div>
        </q-form>
      </div>
    </div>
  </section>
  <AppFooter hideBanner />
</template>

<script setup>
import { useMeta, useQuasar } from 'quasar'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from 'stores/user'
import AppFooter from 'src/components/Footer.vue'
import {
  base64Decode,
  findUsefulMsg,
  getDeviceName,
  getUA,
  metaLinkLocale,
  metaTitleTemplate
} from 'src/utils/util'
import {
  validatePasswordLength,
  validatePasswordLetter,
  validateUsername
} from 'src/filter'

const isLoading = ref(false)
const userStore = useUserStore()
const $q = useQuasar()
const route = useRoute()
const router = useRouter()
const { t, availableLocales } = useI18n()
// const userInfo = ref({})
const eyeIcon = ref(false)
const isPassword = ref(false)

const changeVisibility = () => {
  eyeIcon.value = !eyeIcon.value
  isPassword.value = !isPassword.value
}

const formParams = ref({
  username: '',
  password: '',
  deviceId: 'web',
  platform: 'Web:' + getUA(),
  deviceName: getDeviceName()
})

const redirectByQuery = () => {
  const redirect = base64Decode([].concat(route.query.redirect)[0])
  if (redirect && redirect.startsWith('/')) {
    router.replace(redirect)
    return
  } else {
    router.replace({
      path: '/',
      query: {
        lang: userStore.lang
      }
    })
  }
}

const onSubmit = async () => {
  isLoading.value = true
  await userStore
    .login(formParams.value)
    .then(async (res) => {
      $q.notify({
        type: 'ok',
        message: t('alert.login')
      })

      if (route.query.isSubscribe) {
        const url = `${process.env.U_REDIRECT_URL}subscribe?redirect=w&pid=${route.query.pid}&group=${route.query.group}&name=${route.query.name}&phone=${route.query.phone}&lang=${userStore.lang}&t=${res.token}&d=${userStore.userLoginInfo.deviceId}`
        window.location.replace(url)
        return
      }

      redirectByQuery()
    })
    .catch((err) => {
      $q.notify({
        type: 'error',
        message: findUsefulMsg(
          t('errorCode.' + err.code),
          t('httpCode.' + err.data.status)
        )
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}

useMeta({
  title: t('login.title'),
  titleTemplate: metaTitleTemplate,
  meta: {
    description: {
      name: 'description',
      content: t('login.description')
    }
  },
  link: metaLinkLocale(route.fullPath, availableLocales)
})
</script>
